import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AdminFactorsService } from './admin-factors.service';
import { Calculator } from "./calculationModel/calculator";
import { IConfiguration } from "./calculationModel/configuration";
import { IConfigurationGroup } from "./calculationModel/configurationGroup";
import { CompleteResultSet, CompleteResultSet as ICompleteResultSet } from "./calculationModel/ICompleteResultSet";
import { InputModel as IInputModel, InputModel } from "./calculationModel/inputModel";
import { PriceCalculator } from "./calculationModel/priceCalculator";
import { Prices } from "./calculationModel/prices";
import { ResultLayer } from "./calculationModel/resultLayer";
import { MetricToImpFactors, ImpToMetricFactors } from "./calculationModel/unitSystem";
import { PricesService } from "./prices.service";

interface volumeAndLengthResult {
  volume: number;
  minLength: number;
  maxLength: number;
  averageLenght: number;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  //private configurationsUrl = 'api/Configurations';
  private configurationsUrl = 'Configurations';

  currentChartDataJson: any;
  currentEmbankmentvalues: any;
  currentPriceIndication:number;

  currentCalculation: BehaviorSubject<IConfiguration>;
  currentGroups: BehaviorSubject<IConfigurationGroup[]>;

  currentInputModel: BehaviorSubject<InputModel>;
  currentResultSet: BehaviorSubject<CompleteResultSet>;

  currentCalculationCode: BehaviorSubject<[string,string]>;

  setNewInputModel(newInputModel:InputModel): void {
    this.currentInputModel.next(newInputModel);
  }

  refreshCalculationById(id: string): void {
    if (!id) {
      this.currentCalculation.next(null);
    } else {
      const queryUrl = `${this.configurationsUrl}/${id}`;

      this.http.get(queryUrl).pipe(tap(res => {

        }),
        map(res => {
          const calc = JSON.parse(JSON.stringify(res)) as IConfiguration;

          return calc;
        })).subscribe(calc => {
          this.currentCalculation.next(calc);
          this.currentCalculationCode.next([calc.id, calc.calculationCode]);
      });
    }

  }



  refreshGroups(): void {
    const queryUrl = `${this.configurationsUrl}/getGroups`;
    //const queryUrl = `${this.configurationsUrl}/getGroups`;

    this.http.get(queryUrl).pipe(tap(res => {

      }),
      map(res => {
        const resArray = res as any[];
        if (!resArray) {
          return [];
        }

        const groups = JSON.parse(JSON.stringify(res)) as IConfigurationGroup[];

        return groups;
      })).subscribe(groups => {
        this.currentGroups.next(groups);
    });
  }

  refreshCalculationByCode(code:string): void {
    const queryUrl = `${this.configurationsUrl}/byCode/${code}`;

    this.http.get(queryUrl).pipe(tap(res => {
        
      }),
      map(res => {
        if (!res) {
          const errorMsg = `Keine Config mit Code ${code} gefunden`;
          alert(errorMsg);
          return null;
        }

        const config = JSON.parse(JSON.stringify(res)) as IConfiguration;
        return config;
      })).subscribe(cfg => {
        this.currentCalculation.next(cfg);
        this.currentCalculationCode.next([cfg.id, cfg.calculationCode]);
    });
  }

  createNewSubConfiguration(id: string, title: string): void {
    const postUrl = this.configurationsUrl + '/createNewSubCalculation';

    const dto = {
      masterConfigurationId: id,
      newTitle: title
    };
    this.http.post(postUrl, dto).subscribe(res => {
      alert("Calculation created");
    });

  }

  setInputMetricInputValuesToCalculate(input: InputModel): void {
    if (!input) {
      return null;
    }

    if (input.language !== "enimp") {
      input.metricInputValues = {
        height: input.height,
        unitWeightCharacteristic: input.unitWeightCharacteristic,
        verticalLayerDistance: input.verticalLayerDistance,
        minOverburdenHeight: input.minOverburdenHeight,
        uniformVerticalSurcharge: input.uniformVerticalSurcharge,
        widthOfUniformVerticalSurcharge: input.widthOfUniformVerticalSurcharge,

      }
    } else {
      input.metricInputValues = {
        height: input.height * ImpToMetricFactors.Meter,
        unitWeightCharacteristic: input.unitWeightCharacteristic * ImpToMetricFactors.Weight,
        verticalLayerDistance: input.verticalLayerDistance * ImpToMetricFactors.Meter,
        minOverburdenHeight: input.minOverburdenHeight * ImpToMetricFactors.Meter,
        uniformVerticalSurcharge: input.uniformVerticalSurcharge * ImpToMetricFactors.Pressure,
        widthOfUniformVerticalSurcharge: input.widthOfUniformVerticalSurcharge * ImpToMetricFactors.Meter,

      }
    }


    //input.height * ImpToMetricFactors.Meter;
    //input.unitWeightCharacteristic * ImpToMetricFactors.Pressure;
    //input.verticalLayerDistance * ImpToMetricFactors.Meter;
    //input.minOverburdenHeight * ImpToMetricFactors.Meter;
    //input.uniformVerticalSurcharge * ImpToMetricFactors.Pressure;
    //input.widthOfUniformVerticalSurcharge * ImpToMetricFactors.Meter;

    //return input;
  }



  recalculate(isInputDataValid:boolean): void {
    //if (!isInputDataValid) {
    //  this.currentResultSet.next(null);
    //  return;
    //}
    console.log("Recalc");

    const inputModel = this.currentInputModel.getValue();
    this.setInputMetricInputValuesToCalculate(inputModel);



    if (inputModel) {
      const enrichedInputModel = this.calculateHelpValues(inputModel);
      const calculator = new Calculator(enrichedInputModel, this.adminFactorsService);
      
      this.pricesService.getPriceById(enrichedInputModel.priceId).subscribe(prices => {
        const layers = calculator.calculate(prices).filter(x => x.productLine);

        this.calculatePricesAndSetResultSet(enrichedInputModel, layers, prices);
      });

    } else {
      this.currentResultSet.next(null);
    }
  }

  private calculatePricesAndSetResultSet(inputModel: IInputModel, layers: ResultLayer[], prices: Prices): void {
    const priceCalc = new PriceCalculator(inputModel, layers, prices);

    this.pricesService.getPriceById(inputModel.priceId).subscribe(x => {

      const pricesTable = priceCalc.calculate(x);

      const volumeAndLength = this.calculateVolumeAndLengths(inputModel.metricInputValues.height, layers);

      const result = {
        productPriceDetails: pricesTable,
        resultLayers: layers,
        inputModel: inputModel,
        volume: volumeAndLength.volume,
        maxLength: volumeAndLength.maxLength,
        averageLength: volumeAndLength.averageLenght,
        minLength: volumeAndLength.minLength
      } as ICompleteResultSet;

      this.currentResultSet.next(result);
    });


  }

  //private getMaxLength(layers: ResultLayer[]): number {

  //}

  private calculateVolumeAndLengths(frontHeight:number, layers:ResultLayer[]) : volumeAndLengthResult {
    if (!layers || layers.length === 0) {
      return {
        averageLenght: 0,
        maxLength: 0,
        volume: 0,
        minLength : 0
      } as volumeAndLengthResult;
    }

    const sortedHeightsAndLength = layers.map(x => {
      return {
        height: x.height,
        length: x.resultTotalLength
      }
    }).sort((x, y) => y.length - x.length);

    //const volume = sortedHeightsAndLength.reduce((a, currentValue, index) => {
    //  if (index === 0) {
    //    return a + (currentValue.length * frontHeight);
    //  } else {
    //    return a + (currentValue.height) * (currentValue.height - sortedHeightsAndLength[index - 1].height);
    //  }
    //}, 0);


    const maxLength = sortedHeightsAndLength[0].length;

    const averageLength = sortedHeightsAndLength.reduce((a, currentValue) => {
          return a + currentValue.length;
        },
        0) /
      sortedHeightsAndLength.length;

    return {
      minLength: sortedHeightsAndLength[sortedHeightsAndLength.length - 1].length,
      maxLength : maxLength,
      averageLenght : averageLength,
      volume: averageLength * frontHeight,
    } as volumeAndLengthResult;
  }

  private isZeroOrNull(value: number) {
    if (value === null || value === undefined || isNaN(value) || value === 0) {
      return true;
    }

    return false;
  }

  private calculateHelpValues(inputModel: InputModel): InputModel {

    if (!inputModel) {
      return null;
    }

    const frictionAngleCharacteristicRad = inputModel.frictionAngleCharacteristic * Math.PI / 180;
    const frictionAngleDesignRad = this.isZeroOrNull(inputModel.frictionAngle) ? 0 : Math.tan(frictionAngleCharacteristicRad) / inputModel.frictionAngle;
    const frictionAngleDesign = Math.atan(frictionAngleDesignRad) * 180 / Math.PI;
    const unitWeightDesign = inputModel.metricInputValues.unitWeightCharacteristic * inputModel.unitWeight;
    
    const slopeAngleDegreeFirst = -(90 - inputModel.slopeAngle);
    const slopeAngleDegreeSecond = slopeAngleDegreeFirst * Math.PI / 180;
    const wallFrictionRad = this.isZeroOrNull(inputModel.wallFriction) ? 0 : inputModel.frictionAngleCharacteristic * Math.PI / 180 * inputModel.wallFriction;

    const uniformVerticalSurchargeDesignValue = inputModel.metricInputValues.uniformVerticalSurcharge * inputModel.trafficLoad;
    const effectiveHeight = this.isZeroOrNull(inputModel.metricInputValues.unitWeightCharacteristic) ? 0 : inputModel.metricInputValues.height + uniformVerticalSurchargeDesignValue / inputModel.metricInputValues.unitWeightCharacteristic;

    const angleOfInclinationOfSlipSurfaces =
      this.getAngleOfInclinationOfSlipSurfaces(frictionAngleCharacteristicRad, slopeAngleDegreeSecond, wallFrictionRad, inputModel);

    const angleOfInclinationOfSlipSurfacecesRad = angleOfInclinationOfSlipSurfaces * Math.PI / 180;
    const influencingDepthOfVerticalSurcharge =
      inputModel.metricInputValues.widthOfUniformVerticalSurcharge * Math.tan(angleOfInclinationOfSlipSurfacecesRad);

    const productString = inputModel.petPva === 2 ? "Fortrac® MPT made from PVA" : (inputModel.phValueOfFillingSoil === 2 ? "Fortrac® T made from PET" : "Fortrac® MPT made from PVA");

    inputModel = {
      ...inputModel,
      frictionAngleCharacteristicRad: frictionAngleCharacteristicRad,
      frictionAngleDesignRad: frictionAngleDesignRad,
      frictionAngleDesign: frictionAngleDesign,
      unitWeightDesign: unitWeightDesign,
      slopeAngleDegreeFirst: slopeAngleDegreeFirst,
      slopeAngleDegreeSecond: slopeAngleDegreeSecond,
      wallFrictionRad: wallFrictionRad,
      uniformVerticalSurchargeDesignValue: uniformVerticalSurchargeDesignValue,
      effectiveHeight: effectiveHeight,
      angleOfInclinationOfSlipSurfaces: angleOfInclinationOfSlipSurfaces,
      angleOfInclinationOfSlipSurfacesRad: angleOfInclinationOfSlipSurfacecesRad,
      influencingDepthOfVerticalSurcharge: influencingDepthOfVerticalSurcharge,
      productString: productString,
      numberOfGeogrids: inputModel.metricInputValues.height < (5) ? (3) : (4),
    }
    return inputModel;
  }

  private getAngleOfInclinationOfSlipSurfaces(frictionAngleCharacteristicRad: number, slopeAngleDegreeSecond: number, wallFrictionRad: number, inputModel:InputModel) {
    const result =
    ((Math.cos(frictionAngleCharacteristicRad - slopeAngleDegreeSecond)) /
    (Math.sin(frictionAngleCharacteristicRad - slopeAngleDegreeSecond) +
      Math.sqrt(
        (Math.sin(frictionAngleCharacteristicRad + wallFrictionRad) * (Math.cos(frictionAngleCharacteristicRad)) /
          (Math.sin(frictionAngleCharacteristicRad) + Math.cos(slopeAngleDegreeSecond + wallFrictionRad))))));

    const frictionAngleCharacteristics = inputModel.frictionAngleCharacteristic * 1;

    return result + frictionAngleCharacteristics;
  }



  createNewConfiguration(cfg: IConfiguration, callbackOnSuccess: Function): void {
    cfg.maxLength = this.currentEmbankmentvalues.maxLength;
    cfg.minLength = this.currentEmbankmentvalues.minLength;
    cfg.averageLength = this.currentEmbankmentvalues.averageLength;
    cfg.volume = this.currentEmbankmentvalues.volume;
    cfg.priceIndication = this.currentPriceIndication;

    cfg.chartDataJson = this.currentChartDataJson;
    const postUrl = this.configurationsUrl;
    cfg.id = undefined;
    this.http.post(postUrl, cfg).pipe(map(res => {
      return {
        code: (<any>res).calculationCode,
        id: (<any>res).id
      };
    })).subscribe(res => {
      //alert("Calculation created with Code " + res);
      this.currentCalculationCode.next([res.id, res.code]);
      this.refreshGroups();

      if (callbackOnSuccess) {
        callbackOnSuccess(res.code);
      }

    });
  }

  updateConfiguration(cfg: IConfiguration, callbackOnSuccess: Function): void {
    cfg.maxLength = this.currentEmbankmentvalues.maxLength;
    cfg.minLength = this.currentEmbankmentvalues.minLength;
    cfg.averageLength = this.currentEmbankmentvalues.averageLength;
    cfg.volume = this.currentEmbankmentvalues.volume;
    cfg.priceIndication = this.currentPriceIndication;

    cfg.chartDataJson = this.currentChartDataJson;
    const postUrl = `${this.configurationsUrl}/${cfg.id}`;

    this.http.put(postUrl, cfg)
      .pipe()
      .subscribe(res => {
        
      });

    if (callbackOnSuccess) {
      callbackOnSuccess();
    }
  }

  constructor(private http: HttpClient, private pricesService:PricesService, private adminFactorsService:AdminFactorsService) {
    this.currentCalculation = new BehaviorSubject(null);
    this.currentGroups = new BehaviorSubject([]);
    this.currentInputModel = new BehaviorSubject(null);
    this.currentResultSet = new BehaviorSubject(null);
    this.currentCalculationCode = new BehaviorSubject(null);

    this.refreshGroups();
  }


}
